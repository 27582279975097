import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

import { sortByCreatedAt } from 'helpers/sort';
import Videos from 'blocks/Videos';

export default function LatestVideos({ title, showBottomButton }) {
    const {
        allContentfulVideo: { nodes },
        //allContentfulLatestVideosImage,
        contentfulUtilityJsonModel,
    } = useStaticQuery(graphql`
        query {
            allContentfulVideo {
                nodes {
                    ...ContentfulVideoFields
                }
            }
            # allContentfulLatestVideosImage {
            #   nodes {
            #     ...ContentfulLatestVideosImageFields
            #   }
            # }
            contentfulUtilityJsonModel(type: { eq: "LatestVideosImage" }) {
                type
                content {
                    imageUrl
                }
            }
        }
    `);
    const videos = sortByCreatedAt(nodes)
        .filter(v => v.node_locale === 'en-US')
        .slice(0, 3);

    let keyImage = undefined;
    // if (
    //   allContentfulLatestVideosImage &&
    //   allContentfulLatestVideosImage.nodes &&
    //   allContentfulLatestVideosImage.nodes.length > 0 &&
    //   allContentfulLatestVideosImage.nodes[0] &&
    //   allContentfulLatestVideosImage.nodes[0].image &&
    //   allContentfulLatestVideosImage.nodes[0].image.localFile &&
    //   allContentfulLatestVideosImage.nodes[0].image.localFile.publicURL
    // ) {
    //   keyImage =
    //     allContentfulLatestVideosImage.nodes[0].image.localFile.publicURL;
    // }

    if (
        contentfulUtilityJsonModel &&
        contentfulUtilityJsonModel.content &&
        contentfulUtilityJsonModel.content.imageUrl
    ) {
        keyImage = contentfulUtilityJsonModel.content.imageUrl;
    }

    return (
        <Videos
            videos={videos}
            title={title}
            showBottomButton={showBottomButton}
            bgImage={keyImage}
        />
    );
}
