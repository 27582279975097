import React from 'react';
import get from 'lodash.get';
import { getBlogPostUrl } from 'helpers/blogUrl';

import TOPICS from 'helpers/blogTopics';

import { Container, ColsArticles } from '../../kenra-storybook/index';

export default function BlogArticles({ items }) {
    if (!Array.isArray(items) || items.length === 0) return null;
    let sortedItems = items.map(item => ({
        href: getBlogPostUrl(item),
        img: get(item, 'image.localFile.publicURL'),
        type: item.topic,
        typeColor: get(TOPICS, `${item.topic}.color`),
        authorImg: get(item, 'authorImage.localFile.publicURL'),
        authorName: item.author,
        title: item.title,
        text: `<p>${
            get(item, 'shortText.shortText')
                ? get(item, 'shortText.shortText')
                : ''
        }</p>`,
        // date: `${formatDistanceToNow(parseISO(item.createdAt))} ago`,
    }));

    return (
        <>
            <Container>
                <ColsArticles
                    rows={[
                        {
                            items: sortedItems.slice(0, 2),
                        },
                        {
                            styleSmall: true,
                            items: sortedItems.slice(2),
                        },
                    ]}
                />
            </Container>
        </>
    );
}
