import React, { useState, useEffect } from 'react';

import LatestVideos from 'blocks/LatestVideos';

import BlogNavigation from './BlogNavigation';
import BlogTopSlider from './BlogTopSlider';
import BlogArticles from './BlogArticles';

import { Spacing } from '../../kenra-storybook/index';
import { injectIntl } from 'gatsby-plugin-react-intl';

export const TypesEnum = {
    TRENDING: 'trending',
    HOWTO: 'howto',
    CREATORS: 'creators',
    INSPIRATION: 'inspiration',
};

const Blog = ({ intl, items }) => {
    const [currentFilter, setCurrentFilter] = useState();
    let filteredItems = [];

    useEffect(() => {
        const url = typeof window !== 'undefined' ? window.location.href : '';
        if (url.split('?filter=').length > 1) {
            const type = url.split('?filter=')[1];
            let filterType = '';
            switch (type) {
                case TypesEnum.TRENDING:
                    filterType = 'Trending';
                    break;
                case TypesEnum.HOWTO:
                    filterType = 'How-to';
                    break;
                case TypesEnum.CREATORS:
                    filterType = 'Creators';
                    break;
                case TypesEnum.INSPIRATION:
                    filterType = 'Inspiration';
                    break;
                default:
                    filterType = 'All';
            }
            setCurrentFilter(filterType);
        } else {
            setCurrentFilter('All');
        }
    }, []);

    if (currentFilter === 'All') {
        filteredItems = items;
    } else {
        filteredItems = items.filter(item => {
            // console.log(
            //   filterType,
            //   '=',
            //   item.topic + '?',
            //   filterType === item.topic
            // );
            return currentFilter === item.topic;
        });
    }

    return (
        <>
            <Spacing removeSpaceTop>
                <BlogTopSlider
                    items={filteredItems.filter(item => item.featured)}
                />
            </Spacing>
            <Spacing>
                <BlogNavigation />
            </Spacing>
            <Spacing>
                <BlogArticles items={filteredItems} />
            </Spacing>
            <Spacing>
                <LatestVideos
                    title={intl.formatMessage({ id: 'kenra-tutorial' })}
                />
            </Spacing>
        </>
    );
};

export default injectIntl(Blog);
