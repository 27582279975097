import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import Blog from 'components/Blog';
import SEO from 'components/seo';
import get from 'lodash.get';

export default function BlogPage(props) {
    let {
        data: {
            allContentfulBlogPost: { nodes },
            contentfulPage,
        },
    } = props;

    let storedRegion = '';
    if (typeof window !== 'undefined') {
        storedRegion = localStorage.getItem('preferred-region');
    }

    let blogs = nodes.filter(blog => {
        if (
            typeof blog.region === 'undefined' ||
            blog.region === null ||
            blog.region.length === 0 ||
            blog.region.includes(storedRegion)
        ) {
            return blog.archivedArticle ? false : true;
        } else {
            return false;
        }
    });

    return (
        <Layout>
            <SEO
                description={get(
                    contentfulPage,
                    'seoListing.description.description'
                )}
                title={get(contentfulPage, 'seoListing.seoTitle')}
                slug={'blog'}
            />
            <Blog items={blogs} />
        </Layout>
    );
}

export const query = graphql`
    query($locale: String) {
        allContentfulBlogPost(
            sort: { fields: createdAt, order: DESC }
            filter: { node_locale: { eq: $locale } }
        ) {
            nodes {
                ...ContentfulBlogPostFields
            }
        }
        contentfulPage(title: { eq: "Blog" }, node_locale: { eq: $locale }) {
            ...ContentfulPageFields
        }
    }
`;
