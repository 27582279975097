import React from 'react';
import get from 'lodash.get';
import { getBlogPostUrl } from 'helpers/blogUrl';

import { Slider, SliderInfo, SharedStyles } from '../../kenra-storybook/index';

let Color = require('color');

const { StSliderInfo, StSliderInfoText, StSliderInfoBg } = SliderInfo;
const { StLinkMore } = SharedStyles;

export default function BlogTopSlider({ items }) {
    return (
        <Slider>
            {items.map((item, index) => {
                const bgColor = get(item, 'featuredColor') || '#ffc22c';

                const isDark = Color(bgColor).isDark();
                const fontColor = isDark ? '#fff' : '#000';
                return (
                    <StSliderInfo key={index}>
                        <StSliderInfoBg
                            style={{
                                backgroundImage: `url(${get(
                                    item,
                                    'image.localFile.publicURL'
                                )})`,
                            }}
                        />
                        <StSliderInfoText
                            style={{
                                backgroundColor: bgColor,
                                color: fontColor,
                            }}
                        >
                            <h3 style={{ color: fontColor }}>{item.title}</h3>
                            <hr style={{ borderColor: fontColor }} />
                            <p>{get(item, 'shortText.shortText')}</p>
                            <StLinkMore>
                                <a
                                    style={{
                                        backgroundColor: fontColor,
                                        color: isDark ? '#000' : '#fff',
                                    }}
                                    href={getBlogPostUrl(item)}
                                >
                                    Read
                                </a>
                            </StLinkMore>
                        </StSliderInfoText>
                    </StSliderInfo>
                );
            })}
        </Slider>
    );
}
