import React from 'react';
import { injectIntl } from 'gatsby-plugin-react-intl';

import {
    CategoryNav,
    Container,
    SharedStyles,
} from '../../kenra-storybook/index';

const { StSectionTitle } = SharedStyles;

const BlogNavigation = ({ intl }) => {
    return (
        <>
            <StSectionTitle>
                <Container>
                    <h2>
                        {intl.formatMessage({ id: 'blog-navigation-browse' })}
                    </h2>
                </Container>
            </StSectionTitle>
            <CategoryNav
                items={[
                    {
                        img: '/images/blogCategories/viewall.png',
                        title: intl.formatMessage({
                            id: 'blog-navigation-view-all',
                        }),
                        href: '/blog',
                    },
                    {
                        img: '/images/blogCategories/trending.png',
                        title: intl.formatMessage({
                            id: 'blog-navigation-trending',
                        }),
                        href: '/blog?filter=trending',
                    },
                    {
                        img: '/images/blogCategories/howto.png',
                        title: intl.formatMessage({
                            id: 'blog-navigation-how-to',
                        }),
                        href: '/blog?filter=howto',
                    },
                    {
                        img: '/images/blogCategories/creators.png',
                        title: intl.formatMessage({
                            id: 'blog-navigation-creators',
                        }),
                        href: '/blog?filter=creators',
                    },
                    {
                        img: '/images/blogCategories/inspiration.png',
                        title: intl.formatMessage({
                            id: 'blog-navigation-inspiration',
                        }),
                        href: '/blog?filter=inspiration',
                    },
                ]}
            />
        </>
    );
};

export default injectIntl(BlogNavigation);
